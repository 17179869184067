import {
  CreateVariables,
  NotificationBulkTrashVariables,
  NotificationUpdateVariables,
  UserGetVariables,
  NotificationAutoSendVariables,
  EmailSmsVariables,
} from "./../models/app";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/notifications";
import { HeadCell } from "../models/dataTable";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  Booking,
  Concept,
  Notification,
  ReservationStatus,
  User,
  TimeSlot,
} from "../models";
import useApp from "./useApp";
import useConcept from "./useConcept";
import useStatus from "./useStatus";
import useUser from "./useUser";
import useTimeSlot from "./useTimeSlot";
import {
  NotificationGetVariables,
  NotificationListingVariables,
} from "../models/app";
import { CreateNotificationInput } from "../models/GQL_API";
import { listNotifications } from "../graphql/queries";
import { isValidEmail } from "../helpers/utils";
import { getDomainName } from "../helpers/utils";
import { AccountDomain } from "../constants/enums";
import { sendOtpViaSMS } from "../services/smsService";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError, showWarning } = useApp();
  const { conceptsGetName, conceptsGetOnline } = useConcept(
    "concepts",
    "concept"
  );
  const { statusesGetName, statusesGetOnline, statusesGetByName } = useStatus(
    "statuses",
    "status"
  );
  const { timeSlotsGet } = useTimeSlot("timeSlots", "timeSlot");
  const { guestsGet } = useUser("guests", "guest");
  const conceptsListing: Concept[] = useSelector(
    (state: any) => state.concepts.listing
  );
  const statusesListing = useSelector((state: any) => state.statuses.listing);
  const timeSlotsListing = useSelector((state: any) => state.timeSlots.listing);
  const session = useSelector((state: any) => state.app.session);
  const accountsSelected = useSelector((state: any) => state.accounts.selected);

  async function fetch(params: NotificationListingVariables) {
    const { accountID, conceptID, searchText, startIndex, limit, status } =
      params;

    try {
      const listing: Notification[] = await DataStore.query(
        Notification as any,
        (model: any) => {
          model
            .accountID("eq", accountID)
            .conceptID("eq", conceptID)
            .deleted("eq", "0");

          if (status) model.name("eq", status.toLowerCase());

          if (searchText.length > 0) {
            model.or((model: any) => {
              model.name("contains", searchText.toLowerCase());
              model.channel("contains", searchText.toLowerCase());
              model.from("contains", searchText.toLowerCase());
            });
          }

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
      return [];
    }
  }

  async function fetchOnline() {
    const filter: any = { deleted: { eq: "0" } };
    const limit: any = 1000;

    try {
      const listing: any = await API.graphql({
        query: listNotifications,
        variables: {
          limit,
          filter,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.listNotifications.items;
    } catch (err) {
      throw err;
    }
  }

  async function fireNotifications(params: NotificationListingVariables) {
    const { booking, guests, concepts, statuses, slots, channel } = params;

    if (!booking) {
      const error = new Error("Cannot send notification without booking");
      return showError(error);
    }
    // if (!status) const error = new Error("Cannot send notification without status");
    if (!guests) {
      const error = new Error("Cannot send notification without users");
      return showError(error);
    }
    if (!concepts) {
      const error = new Error("Cannot send notification without concepts");
      return showError(error);
    }
    if (!statuses) {
      const error = new Error("Cannot send notification without statuses");
      return showError(error);
    }
    if (!slots) {
      const error = new Error("Cannot send notification without slots");
      return showError(error);
    }

    try {
      let status = statusesGetName({
        id: booking.statusID,
        listing: statuses,
      }).toLowerCase();
      const paramsGetUser: UserGetVariables = {
        id: booking.mainGuest,
        listing: guests,
      };
      let mainGuest: User = await guestsGet(paramsGetUser);
      let notifications: Notification[] = await fetch(params);

      if (notifications && notifications.length > 0) {
        for (let notification of notifications) {
          if (notification.channel === channel) {
            if (notification.enabled) {
              await sendNotification(
                notification,
                booking,
                mainGuest,
                concepts,
                statuses,
                slots
              );
            } else {
              showWarning(
                `Notification ${notification.name} is disabled for status ${status}`
              );
            }
          }
        }
      } else {
        showWarning("No notifications found for this status in this concept");
      }
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  async function autoFireNotifications(params: NotificationListingVariables) {
    const { booking, guests, concepts, statuses, slots, channel } = params;

    if (!booking) {
      const error = new Error("Cannot send notification without booking");
      return showError(error);
    }
    //if (!status) const error = new Error("Cannot send notification without status");
    if (!guests) {
      const error = new Error("Cannot send notification without guests");
      return showError(error);
    }
    if (!concepts) {
      const error = new Error("Cannot send notification without concepts");
      return showError(error);
    }
    if (!statuses) {
      const error = new Error("Cannot send notification without statuses");
      return showError(error);
    }
    if (!slots) {
      const error = new Error("Cannot send notification without slots");
      return showError(error);
    }

    try {
      const paramsGetUser: UserGetVariables = {
        id: booking.mainGuest,
        listing: guests,
      };

      let status = statusesGetName({
        id: booking.statusID,
        listing: statuses,
      }).toLowerCase();
      let mainGuest: User = await guestsGet(paramsGetUser);
      let notifications: Notification[] = await fetch(params);

      if (notifications && notifications.length > 0) {
        for (let notification of notifications) {
          if (notification.channel === channel) {
            if (notification.enabled && notification.autoSend) {
              await sendNotification(
                notification,
                booking,
                mainGuest,
                concepts,
                statuses,
                slots
              );
            } else {
              showWarning(
                `AutoSend is disabled for notification ${notification.name} for status ${status}`
              );
            }
          }
        }
      } else {
        showWarning("No notifications found for this status in this concept");
      }
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  async function sendNotification(
    notification: Notification,
    booking: Booking,
    mainGuest: User,
    concepts: Concept[],
    statuses: ReservationStatus[],
    slots: TimeSlot[]
  ) {
    // Email notification
    if (notification.channel === "email") {
      if (mainGuest && mainGuest.email) {
        await sendEmail(
          notification,
          booking,
          mainGuest.email,
          concepts,
          statuses,
          slots
        );
        // showConfirm("Email send successfully");
      } else {
        showWarning(`No email address found for ${mainGuest.name}`);
      }
    }
    // SMS notification
    if (notification.channel === "sms") {
      if (mainGuest && mainGuest.phone_number) {
        let conceptName = conceptsGetName({
          id: booking.conceptID,
          listing: conceptsListing,
        });
        if (!mainGuest.email || mainGuest.email === "") {
          //if no email in resend sms.send sms with Confirm email instead
          await sendEmailSMS({ notification, booking, conceptName });
        } else {
          await sendSMS(
            notification,
            booking,
            mainGuest.phone_number,
            conceptName
          );
        }
      } else {
        showWarning(`No phone number found for ${mainGuest.name}`);
      }
    }
  }

  function createHashLink(booking: Booking) {
    return `${window.origin}/reservations/${booking.id}`;
  }

  async function sendEmail(
    notification: Notification,
    booking: Booking,
    email: string,
    conceptsListing: Concept[],
    statuses: ReservationStatus[],
    slots: TimeSlot[]
  ) {
    // ==> Find status by name
    let notificationStatus = await statusesGetByName({
      name: notification.name,
      listing: statusesListing,
    });

    let statusID = notificationStatus.id;

    // Check SMS (booking status)
    if (statusID !== booking.statusID) {
      // console.log("There is no Email template for this booking status");
      return;
    }

    const accountDomain = getDomainName(accountsSelected);
    let bookingLink = createHashLink(booking);
    let customerName = booking.customerName;
    let customerEmail = email;
    let reservationDate = booking.date;
    let reservationTime = "";

    // Email Validation
    if (!isValidEmail(customerEmail)) {
      console.error(`Invalid Email!! ${customerEmail}`);
      // showWarning(`Invalid Email!! ${customerEmail}`);

      return;
    }

    for (let slot of booking?.timeSlots!) {
      let slotName = await timeSlotsGet({
        id: slot,
        listing: slots,
      });
      let from = slotName.name.split("-")[0];
      let to = slotName.name.split("-")[1];
      reservationTime += "from " + from + "to " + to + " ";
    }
    let numOfGuests = booking.accompaniedCount;

    // get concept name online
    let conceptName = await conceptsGetOnline(booking.conceptID, session);
    let statusesName = await statusesGetOnline(booking.statusID, session);
    let templateName = `${conceptName.name}${statusesName.name}`;

    switch (templateName) {
      case "Sachi AlmazaReserved":
        templateName = "AlmazaReserved";
        break;
      case "Sachi AlmazaConfirmed":
        templateName = "AlmazaConfirmed";
        break;
      case "Sachi HeliopolisReserved":
        templateName = "HelioplisReserved";
        break;
      case "Sachi HeliopolisConfirmed":
        templateName = "HelioplisConfirmed";
        break;
      case "Sachi ParkReserved":
      case "Sachi Park St.Reserved":
        templateName = "ParkReserved";
        break;
      case "Sachi ParkConfirmed":
      case "Sachi Park St.Confirmed":
        templateName = "ParkConfirmed";
        break;
      case "Reif 5AReserved":
      case "Reif MarassiReserved":
        templateName = "ReifReserved";
        break;
      case "Reif 5AConfirmed":
      case "Reif MarassiConfirmed":
        templateName = "ReifConfirmed";
        break;
      case "Megumi AlmazaReserved":
      case "Megumi GounaReserved":
        templateName = "MegumiReserved";
        break;
      case "Megumi AlmazaConfirmed":
      case "Megumi GounaConfirmed":
        templateName = "MegumiConfirmed";
        break;
      case "Test ConceptReserved":
        templateName = "ParkConfirmed";
        break;
      case "Yangtze QùReserved":
        templateName = "YangtzeReserved";
        break;
      case "Yangtze QùConfirmed":
        templateName = "YangtzeConfirmed";
        break;
    }

    const SOURCE_EMAIL = "info@anyware.software";
    const QUERY_PARAMS = `?customer_name=${customerName}&customer_email=${customerEmail}&reservation_date=${reservationDate}&reservation_time=${reservationTime}&number_of_guests=${numOfGuests}&source_email=${SOURCE_EMAIL}&template_name=${templateName}&booking_link=${bookingLink}`;

    let MAIL_URL = "";
    if (accountDomain === AccountDomain.TROPITEL_DOMAIN) {
      // Main (Tropitel env) - https://Tropitel.anyware.software
      MAIL_URL = `https://adnmhtt3u2xqojuuxlj6532zzy0qkjzj.lambda-url.us-east-2.on.aws/${QUERY_PARAMS}`;
    } else if (accountDomain === AccountDomain.BAKY_DOMAIN) {
      // Main (Develop env) - https://baky.anyware.software
      MAIL_URL = `https://talnzuxrs26b7pgcqri4awulwa0dddtq.lambda-url.us-east-2.on.aws/${QUERY_PARAMS}`;
    }

    let mailSent = false;
    try {
      await window
        .fetch(MAIL_URL)
        .then((response) => {
          response.status === 200 ? (mailSent = true) : (mailSent = false);
        })
        .catch((err) => {
          mailSent = false;
        });

      if (mailSent) {
        console.log("email", { booking, email, templateName });
        showConfirm("Email send successfully");
      } else {
        showWarning(`Failed to send email for this customer.`);
      }
    } catch (err) {
      console.log(err);
      showWarning(`Failed to send email for this customer.`);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: NotificationGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Notification) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: NotificationGetVariables) {
    const { id, listing } = params;

    try {
      const single: Notification | undefined =
        listing.length === 0
          ? await DataStore.query(Notification as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateNotificationInput = {
        accountID: data.accountID,
        conceptID: data.conceptID,
        name: data.name.toLowerCase(),
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.channel) createInput.channel = data.channel.toLowerCase();
      if (data.message) createInput.message = data.message;
      if (data.from) createInput.from = data.from.toLowerCase();
      if (data.enabled) createInput.enabled = data.enabled;
      if (data.autoSend) createInput.autoSend = data.autoSend;

      const model: Notification = await DataStore.save(
        new Notification(createInput as any)
      );
      showConfirm(`New ${singleName} has been created successfully`);
      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: NotificationUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        Notification.copyOf(original!, (updated) => {
          updated.name =
            data.name !== undefined ? data.name.toLowerCase() : original!.name;
          updated.channel =
            data.channel !== undefined
              ? data.channel.toLowerCase()
              : original!.channel;
          updated.message =
            data.message !== undefined ? data.message : original!.message;
          updated.from =
            data.from !== undefined ? data.from.toLowerCase() : original!.from;
          updated.enabled =
            data.enabled !== undefined ? data.enabled : original!.enabled;
          updated.autoSend =
            data.autoSend !== undefined ? data.autoSend : original!.autoSend;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: NotificationGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Notification.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: NotificationBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: NotificationGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function sendSMS(
    notification: Notification,
    booking: Booking,
    phone: string,
    conceptName: string
  ) {
    // ==> Find status by name
    let notificationStatus = await statusesGetByName({
      name: notification.name,
      listing: statusesListing,
    });

    // Check SMS (booking status)
    if (!notificationStatus || notificationStatus.id !== booking.statusID) {
      console.log("There is no SMS for this booking status");
      return;
    }

    // Send SMS
    const accountDomain = getDomainName(accountsSelected);
    let bookingLink = createHashLink(booking);
    const smsText =
      notification && notification.message
        ? notification.message
        : "Thank you for your reservation. Please confirm your reservation from the following link:";
    const message = `Hello ${booking.customerName}, ${smsText} ${bookingLink}`;

    // let SMS_URL = "";
    // if (accountDomain === AccountDomain.TROPITEL_DOMAIN) {
    //   // Main (tropitel env) - https://Tropitel.anyware.software
    //   SMS_URL = `https://wjkeipmu3qipedgpenxnwpou7q0layjd.lambda-url.us-east-2.on.aws/`;
    // } else if (accountDomain === AccountDomain.BAKY_DOMAIN) {
    //   // Main (bakyprod env) - https://baky.anyware.software
    //   SMS_URL = `https://hz3sa4u4qlrkytvcsu34ktptsa0rewud.lambda-url.us-east-2.on.aws/`;
    // }

    // SMS_URL += `?phone=${phone}&message=${message}&conceptName=${conceptName}`;

    try {
      // await window
      //   .fetch(SMS_URL)
      //   .then((response: any) => {
      //     if (response.status === 200) {
      //       showConfirm("SMS send successfully");
      //     }
      //   })
      //   .catch((err) => {
      //     showError(
      //       typeof err.message === "string"
      //         ? err.message
      //         : "error occurred while sending SMS"
      //     );
      //   });

      const smsResponse = await sendOtpViaSMS(phone, message, conceptName);

      console.log({
        smsResponse,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function sendEmailSMS(params: EmailSmsVariables) {
    const { notification, booking, conceptName } = params;

    // ==> Find status by name
    let notificationStatus = await statusesGetByName({
      name: notification.name,
      listing: statusesListing,
    });

    let statusID = notificationStatus.id;

    // Check SMS (booking status)
    if (statusID !== booking.statusID) {
      console.log("There is no Email template for this booking status");
      return;
    }

    // Send Email
    const accountDomain = getDomainName(accountsSelected);
    const bookingLink = `${window.origin}/email/${booking.id}/${booking.conceptID}`;
    const smsText =
      notification && notification.message
        ? notification.message
        : `Thank you for your reservation at ${conceptName}. Please confirm your reservation from the following link:`;
    const message = `Hello ${booking.customerName}, ${smsText} ${bookingLink}`;

    // let SMS_URL = "";
    // if (accountDomain === AccountDomain.TROPITEL_DOMAIN) {
    //   // Main (tropitel env) - https://Tropitel.anyware.software
    //   SMS_URL = `https://wjkeipmu3qipedgpenxnwpou7q0layjd.lambda-url.us-east-2.on.aws/`;
    // } else if (accountDomain === AccountDomain.BAKY_DOMAIN) {
    //   // Main (bakyprod env) - https://baky.anyware.software
    //   SMS_URL = `https://hz3sa4u4qlrkytvcsu34ktptsa0rewud.lambda-url.us-east-2.on.aws/`;
    // }

    // SMS_URL += `?phone=${booking.customerPhone}&message=${message}&conceptName=${conceptName}`;

    try {
      // await window
      //   .fetch(SMS_URL)
      //   .then((response: any) => {
      //     if (response.status === 200) {
      //       showConfirm("SMS send successfully");
      //     }
      //   })
      //   .catch((err) => {
      //     showError(
      //       typeof err.message === "string"
      //         ? err.message
      //         : "error occurred while sending SMS"
      //     );
      //     console.log(err);
      //   });

      const smsResponse = await sendOtpViaSMS(
        booking.customerPhone,
        message,
        conceptName
      );

      console.log({
        smsResponse,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function checkAutoSendStatus(params: NotificationAutoSendVariables) {
    let { booking, guest, conceptID, isDirect = true } = params;
    const concept: Concept | undefined = await conceptsGetOnline(
      conceptID,
      session
    );

    if (!concept) return showError("Invalid booking concept");

    try {
      const userNotificationsModules: any | undefined = await fetchOnline();
      let ConceptNotificationsList: any[] = [];

      for (let i = 0; i < userNotificationsModules.length; i++) {
        if (userNotificationsModules[i].conceptID === booking.conceptID)
          ConceptNotificationsList = [
            ...ConceptNotificationsList,
            userNotificationsModules[i],
          ];
      }

      if (!ConceptNotificationsList || ConceptNotificationsList.length < 1)
        return showError("Concept is not existing");
      let smsChannel = false;
      let emailChannel = false;
      for (let item of ConceptNotificationsList) {
        if (!item.autoSend) {
          continue;
        }

        if (item.channel === "sms") {
          smsChannel = true;
          emailChannel = false;
          // await sendSMS(booking, guest.phone_number);
        } else if (item.channel === "email") {
          emailChannel = true;
          smsChannel = false;
        }

        // send sms only if sms auto send is on.** && smsChannel **
        // to verify email
        if (emailChannel) {
          const emailSmsParams: EmailSmsVariables = {
            notification: item,
            booking: booking,
            conceptName: concept.name,
          };

          if (!guest.email || guest.email === "") {
            sendEmailSMS(emailSmsParams);
            return;
          } else if (guest.email && guest.email !== "") {
            sendEmail(
              item,
              booking,
              guest.email,
              conceptsListing,
              statusesListing,
              timeSlotsListing
            );
          }
        }

        if (smsChannel && isDirect && (guest.email || guest.email !== "")) {
          sendSMS(item, booking, guest.phone_number, concept.name);
        }
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "channel",
      numeric: false,
      disablePadding: false,
      label: "Channel",
    },
    {
      id: "enabled",
      numeric: false,
      disablePadding: false,
      label: "Enabled",
    },
    {
      id: "autoSend",
      numeric: false,
      disablePadding: false,
      label: "Auto Send",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [
    "name",
    "channel",
    "enabled",
    "autoSend",
  ];

  const api: any = {};

  api[`${listingName}Model`] = Notification as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Fire`] = fireNotifications;
  api[`${listingName}AutoFire`] = autoFireNotifications;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}CheckAutoSendStatus`] = checkAutoSendStatus;
  api[`${listingName}ChangeListing`] = (listing: Notification[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
